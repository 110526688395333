import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts from 'highcharts';

export function TemperatureChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][] | [number, number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	return {
		chart: {
			styledMode: false,
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			/*events: {
                load: !simpleMode
                    ? function () {
                            const chart = this;

                            // Bouton de réinitialisation du zoom
                            chart.renderer
                                .button('Reset zoom', 0, 0, function () {
                                    chart.zoomOut();
                                })
                                .attr({ zIndex: 999 })
                                .align({ align: 'right', y: 90, x: -100 })
                                .add();
                        }
                    : null,
            },*/
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: 'Température',
						},
					},
				},
			],
		},
		plotOptions: {
			column: {
				pointPlacement: 'between',
				pointRange: 0,
			},
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: !simpleMode,
		},
		legend: {
			enabled: !simpleMode,
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Mesures instantanées ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20, // Déplace le titre plus haut que le début du graphique
			style: {
				fontSize: '20px',
			},
			margin: 40,
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: {
			title: {
				text: 'Mesures instantanées (°C)',
				align: 'high', // Aligne le titre de l'axe gauche en haut
				textAlign: 'left',
				y: -15, // Déplace le titre plus haut que le début du graphique
				rotation: 0, // Désactive la rotation du titre de l'axe gauche
				style: {
					color: '#628fc9', // Utilise la première couleur de la palette Highcharts,
					fontSize: '13px',
					fontWeight: 'bold',
					width: 250,
				},
			},
			labels: {
				format: `{value}`,
			},
			opposite: false,
		},
		boost: {
			enabled: true,
		},
		series: [
			{
				type: 'line',
				name: 'mesures instantanées',
				data: mesures,
				dataGrouping: {
					enabled: false,
				},
				zoneAxis: 'y',
				//color: "#0033cc",
				/*zones: [
                    {
                        value: 0,
                        color: "#0033cc",
                        fillColor: "#0033cc",
                    },
                    {
                        value: 14,
                        color: "#3399ff",
                        fillColor: "#3399ff",
                    },
                    {
                        value: 17,
                        color: "#33cc33",
                        fillColor: "#33cc33",
                    },
                    {
                        value: 21,
                        color: "#ff9900",
                        fillColor: "#ff9900",
                    },
                    {
                        value: 24,
                        color: "#ff3300",
                        fillColor: "#ff3300",
                    },
                    {
                        value: 500,
                        color: "#ff3300",
                        fillColor: "#ff3300",
                    }
                ],*/
				tooltip: {
					valueSuffix: `  ${sensor.unite}`,
				},
			},
		],
	};
}
