import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts, { PointOptionsObject } from 'highcharts';

export function OtherMeasureChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][] | [number, number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	return {
		chart: {
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			/*events: {
                load: !simpleMode ? function () {
                    const chart = this;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", y: 90, x: -100 })
                        .add();
                } : null,
            },*/
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: sensor.type_energie,
						},
					},
				},
			],
		},
		accessibility: {
			enabled: false,
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: simpleMode ? false : true,
		},
		legend: {
			enabled: simpleMode ? false : true,
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Mesure instantanée ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20, // Déplace le titre plus haut que le début du graphique
			style: {
				fontSize: '20px',
			},
			margin: 40,
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: [
			{
				title: {
					text: `Mesures instantanées (${sensor.unite})`,
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left', // Alignement du texte du titre de l'axe gauche
					y: -15, // Déplace le titre plus haut que le début du graphique
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: '#628fc9', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: false,
				//endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
			},
		],
		boost: {
			enabled: true,
		},
		series: [
			{
				type: 'area',
				name: 'mesures instantanées',
				data: mesures as (number | [string | Date, number] | PointOptionsObject)[],
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.4 },
					stops: [
						[0, Highcharts.getOptions().colors[0].toString()],
						[
							1,
							Highcharts.color(Highcharts.getOptions().colors[0])
								.setOpacity(0)
								.get('rgba')
								.toString(),
						],
					],
				},
				tooltip: {
					valueSuffix: `  ${sensor.unite}`,
				},
			},
		],
	};
}
